<template>
  <v-card elevation="0">
    <v-card-title class="black">
      <h3 class="white--text">
        {{ $t('finances.payments') }}
      </h3>
    </v-card-title>

    <v-data-table
      :headers="paymentsTableHeaders"
      :items="paymentItem"
      :loading="isLoading"
      :items-per-page="itemsPerPage"
      :page="currentPage"
      hide-default-footer
      @page-count="getPageCount($event)"
    >
      <template #item.isSubscription="{ item }">
        {{ item.metadata.isSubscription ? $t('main.yes') : $t('main.no') }}
      </template>

      <template #item.amount="{ item }">
        <span v-if="adNetwork.settings.currencySignIsShown">$</span>
        {{ toFixedByDefault(item.amount) }}
      </template>

      <template #item.invoiceDownloadUrl="{ item }">
        <c-btn
          v-if="item.isDownloadable"
          color="primary"
          small
          label="PDF"
          :loading="documentIsLoading[item.id]"
          @click="fetchDocument(item.id)"
        />
      </template>

      <template #item.paidAt="{ item }">
        <div v-if="item.paidAt">
          {{ item.paidAt }}
        </div>
        <div v-else>
          <c-btn color="primary" class="mr-2" small :label="$t('finances.pay')" @click="payInvoice(item)" />
          <c-btn
            color="primary"
            small
            :loading="deleteIsLoading[item.id]"
            :label="$t('finances.delete')"
            @click="deleteInvoice(item.id)"
          />
        </div>
      </template>

      <template #footer>
        <data-table-footer
          :items-per-page="itemsPerPage"
          :page-count="pageCount"
          :current-page="currentPage"
          @change-items-per-page="changeItemsPerPage($event)"
          @change-current-page="changeCurrentPage($event)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapState } from 'vuex'
  import { getPaymentStrategy } from '@clickadilla/components/PaymentStrategies/index.js'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import guardSections from '@/types/guard-sections.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import handleErrors from '@/services/handleErrors.js'
  import { invoicedRepository, paymentsRepository } from '@/services/repository-factory.js'

  export default {
    name: 'PaymentsTable',
    components: {
      DataTableFooter,
      CBtn
    },
    data() {
      return {
        isLoading: false,
        deleteIsLoading: {},
        documentIsLoading: {},
        payments: [],
        itemsPerPage: 15,
        pageCount: 0,
        currentPage: 1
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      paymentsTableHeaders() {
        return [
          { text: this.$t('main.amount'), value: 'amount' },
          { text: this.$t('finances.autopayment'), value: 'isSubscription' },
          { text: this.$t('finances.payment_method'), value: 'paymentMethodName' },
          { text: this.$t('finances.comment'), value: 'number' },
          { text: this.$t('finances.created_at'), value: 'createdAt' },
          {
            text: this.$t('finances.download'),
            value: 'invoiceDownloadUrl',
            isShown: this.adNetworkHasSectionInvoiceDownloads
          },
          { text: this.$t('finances.paid_at'), value: 'paidAt' }
        ].filter((header) => header.isShown !== false)
      },
      adNetworkHasSectionInvoiceDownloads() {
        return this.adNetwork.guardSections.some(
          ({ name }) => name === guardSections.INVOICE_DOWNLOADS
        )
      },
      paymentItem() {
        return this.payments.map((payment) => ({
          ...payment,
          paymentMethodName: this.paymentNameByLocale(payment.paymentMethod).name
        }))
      }
    },
    mounted() {
      this.fetchPayments()
    },
    methods: {
      paymentNameByLocale(paymentMethod) {
        const foundTranslationsPayment = paymentMethod?.translations.find(
          ({ locale }) => locale === this.$i18n.locale
        )
        if (!foundTranslationsPayment) {
          const foundEnTranslations = paymentMethod?.translations.find(
            ({ locale }) => locale === 'en'
          )
          return foundEnTranslations || {}
        }
        return foundTranslationsPayment
      },
      async fetchPayments() {
        this.isLoading = true
        try {
          this.payments = await paymentsRepository.list()
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      changeItemsPerPage(event) {
        this.itemsPerPage = event
      },
      getPageCount(event) {
        this.pageCount = event
      },
      changeCurrentPage(event) {
        this.currentPage = event
      },
      async payInvoice(payment) {
        gtmPush({
          event: GTM_EVENTS.INVOICE_PAY,
          event_category: GTM_EVENTS_CATEGORY.REVENUE
        })
        const { strategy } = payment.paymentMethod
        await getPaymentStrategy(strategy)(payment.formParams)
        await this.fetchPayments()
      },
      async deleteInvoice(id) {
        this.deleteIsLoading = { ...this.deleteIsLoading, [id]: true }
        try {
          await invoicedRepository.delete(id)
          this.$showSuccessNotification(this.$t('finances.invoice_deleted'))
          await this.fetchPayments()
        } catch (error) {
          handleErrors(error)
        }
        this.deleteIsLoading[id] = false
      },
      async fetchDocument(invoiceId) {
        this.documentIsLoading = { ...this.documentIsLoading, [invoiceId]: true }
        try {
          await invoicedRepository.document(invoiceId)
        } catch (error) {
          handleErrors(error)
        }
        this.documentIsLoading[invoiceId] = false
      },
      toFixedByDefault
    }
  }
</script>
